import React from "react"
import { graphql } from 'gatsby'
 
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import Pager from "../components/Pager"
import { Row}  from 'react-bootstrap'

import * as styles from '../styles/list-item.module.css'

import ArticlePreview from '../components/ArticlePreview'
const ASPNET_Example_List = ({ data, pageContext }) => {

    const examples = data.allMarkdownRemark.nodes;

    return (
        <Layout>
            <Seo
                title=".NET Tutorial"
                description="List of tutorials builds on .NET"
                keywords="C# for beginners, Learn C# Programming, ASP.NET Tutorial for Beginners, C# Tutorial for Beginners, .NET, .NET 6, .NET Core, .NEt 5"
            />     
            <h1>.NET Tutorials</h1>
            <div className={styles.lesson}>
                <Row className={styles.list}>
                    <ArticlePreview
                    title={" .NET 6 for beginners"}
                    link = {"/tutorials/asp-dot-net/introduction"}
                    id = {"aspnet-for-beginners"}
                    image={"/images/tutorials/aspnet/dotnet6/logo.png"}
                    alt={"asp.net"}
                    /> 
                    <ArticlePreview
                    title={"C# for beginners"}
                    link = {"/tutorials/c-sharp/introduction"}
                    id = {"csharp-for-beginners"}
                    image={"/images/tutorials/aspnet/csharp/csharp.png"}
                    alt={"c#"}
                    />
                </Row>
            </div>
                 
            <Row className={styles.list}>
                {examples.map(item => (
               <ArticlePreview key={item.id}
                   title={item.frontmatter.title}
                   link = {"/" + item.frontmatter.slug}
                   published= {item.frontmatter.published}
                />           
            ))}
            </Row>
            <Pager pageContext={pageContext} />
        </Layout>
    )
}


export const query= graphql`
query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
        filter: {fileAbsolutePath: {glob: "**/examples/aspnet/*"}}
        sort: {order: DESC, fields: frontmatter___published}
        skip: $skip,
        limit: $limit
        ) {
        nodes {
            frontmatter {
                slug
                title
                published(formatString: "MMMM Do, YYYY")
            }
            id
        }
    }
  }

`

export default ASPNET_Example_List;